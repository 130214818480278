import $ from 'jquery';

import './sass/style.scss';
import './js/app.js';

$(document).on('change', '#select_all', function () {
    if ($(this).is(':checked')) {
        $(this).parent().parent().find('input[type=checkbox]').prop('checked', true);
    } else {
        $(this).parent().parent().find('input[type=checkbox]').prop('checked', false);
    }
});

$('#show_coupon_form').on('click', () => {
    $('#coupon_form').slideToggle();
});

$(document).on('change', '.variation-switcher', function () {
    const cart_item_key = $(this).data('cart-item-key');
    const variation_id = $(this).find('input[type="radio"]:checked').val();

    $.ajax({
        type: 'POST',
        url: jsData.ajaxUrl,
        data: {
            action: 'change_variation_in_cart',
            cart_item_key,
            variation_id,
        },
        success(response) {
            if (response.success) {
                $(document.body).trigger('update_checkout');
            } else {
                alert('Error: Unable to change variation.');
            }
        },
    });
});
